import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { Layout } from 'layout';

import SectionTitle from 'common/SectionTitle';

import { LdsPageProps } from './models';
import './LdsPage.scss';

const LdsPage: FC<LdsPageProps> = ({
  pageContext,
  data: {
    allLds: {
      nodes: [lds],
    },
  },
}) => {
  const { body } = pageContext;
  const { title } = lds;

  return (
    <Layout className="lds-page" seo={lds}>
      <SectionTitle text={title} isMainTitle />
      <Container>
        <Row className="justify-content-md-center">
          <Col lg="9">
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export const query = graphql`
  query($businessId: String) {
    allLds(filter: { businessId: { eq: $businessId } }) {
      nodes {
        title
        ...SeoFragment
      }
    }
  }
`;

export default LdsPage;
